.dropzone.files_like_list{
  padding: 5px;
  font-size: 14px;
  min-height: 1em;
  .dz-preview{
    width: 100%;
    min-height: 1em;
    margin: 0 0 3px 0;
    .dz-image{
      width: 100%;
      height: 2.5em;
      border-radius: 0;
      img {display: none}
      &:before{
        float: left;
        font-size: 1.4em;
        line-height: 1.8em;
        padding: 0 6px;
      }
    }
    .dz-details{
      .dz-size{
        float: right;
        margin: 0 25px 0 15px;
        span{background-color: transparent}
      }
      .dz-filename{
        text-align: left;
        padding-left: 25px;
        span{background-color: transparent}
      }
    }
    .dz-error-mark{
      display: none;
    }

    &:after{
      font-family: FontAwesome;
      content: '\f00d';
      position: absolute;
      padding: 0 5px;
      top: 0;
      right: 0;
      z-index: 10;
      line-height: 2.5em;
    }
  }
  .dz-message{
    margin: 0;
  }
  &:not(.for_one_file){
    &.dz-started:after{
      display: block;
      content: '+ натисніть, щоб додати файли';
      text-align: center;
      &:hover{ color: blue}
    } }
}
