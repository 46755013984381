$text-color                 : #73879C;
$text-color2                : $color2;
$cfa_color                  : #FE6000;
$bns_color                  : #6D00D9;
$monolit_color              : #204000;
$brick_color                : #330000;
$good_color                 : darkgreen;
$bad_color                  : red;

$badge-work-type-width: 90px; // ширина бейджа work-type

td,
.group_date{
  & > {
    .good{color: $good_color}
    .bad{color: $bad_color}
  }
}
.object_worktype {
  //cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .2);
  .x_title {
    margin-bottom: 0;
  }

  h2 {
    //padding-right: $badge-work-type-width;
    padding-right: 20px;
  }
  .description {
    //margin-top: -11px;
    padding: 3px 0px;
    font-size: .85em;
    text-align: justify;
    height: 4.4em;
    overflow-y: hidden;
  }
  .value {
    font-weight: 700
  }
  .data {
    text-align: right;
    white-space: nowrap;
  }
  .ico {
    padding-left: 10px;
    font-size: 1.25em;
  }

  .doc-list {
    tr:hover td {
      background-color: transparent
    }
  }
  &:hover {
    border-color: #7e7e7e;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .7);
    .btn-group {
      //.fa:before, .fa:after{
      //  color: #fff !important;
      //}
      .put-to-box:after {
        text-shadow: -1px -1px 0.02px buttonface, 1px -1px 0.02px buttonface, -1px 1px 0.02px buttonface, 1px 1px 0.02px buttonface;
      }
      .btn {
        //background-color: $color2;
        //color: #fff;
        &:hover {
          background-color: darken($color2, 5%);
          border-color: darken($color2, 9%);
          color: #fff;
          .fa:before, .fa:after {
            color: #fff !important;
          }
        }
        &.btn-primary {
          background-color: #337ab7;
          border-color: #2e6da4;
          color: #fff;
          .fa:before, .fa:after {
            color: #fff !important;
          }
          &:hover {
            background-color: #286090;
            border-color: #204d74;
          }
        }
      }
    }
    .x_footer {
      .collapse-link {
        color: $color2;
      }
    }
  }
}

.x_footer {
  .collapse-link {
    cursor: pointer;
    .content {
      margin-left: .7em;
    }
    .fa-chevron-up + .content:after {
      content: "Згорнути";
    }
    .fa-chevron-down + .content:after {
      content: "Повна інформація про об'єкт";
    }
  }
}

.btn-group {
  .btn {
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2);
    .fa:before, .fa:after {
      color: $text-color !important;
    }
    &.btn-sm {
      .fa {
        font-size: 1.15em;
      }
    }
    &.btn-primary {
      color: $text-color;
      background-color: #ddd;
      border-color: transparent;
    }
  }
}

//---tables with subgroup-cell
.subgroup-cell{
  &, & + td {
    border-top: none !important;
    border-bottom: none !important;
  }
  padding-left: 40px !important;
}
.group-cell{
  &, & + td {
    border-bottom: none !important;
  }
  font-weight: bold;
}
tr.separator-before{
  td{
    border-top: 3px double #333 !important;
  }
}
@media screen and (max-width: $screen-xxs-max) {
  .actions-btn .btn-group {
    .btn {
      margin: 6px;
      display: block;
      width: 100% !important;
      float: none;
      &.btn-round {
        border-radius: 30px !important;
      }
    }
  }
}

.actions-btn {
  margin: 15px -20px;
}

%btn-expand {
  margin: 15px 0;
  .btn-group {
    width: 100%
  }
  .btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@for $i from 1 through 12 {
  .btn-expand-#{$i} {
    @extend %btn-expand;
    .btn {
      width: 100% / $i !important;
    }
  }
}

.breadcrumbs {
    padding-left: 20px;
}
.breadcrumbs {
  span {
    color: $text-color2;
    margin-left: 6px;
  }
  a {
    color: $color1;
    margin-right: 6px;
    &:hover {
      color: $text-color2;
    }
  }
  & + h3 {
    color: $color1 !important;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
  }
}

.metadata, .journals {
  margin: 15px 0;
}

.work-type {
  display: inline-block;
  background-color: transparent;
  position: absolute;
  right: 0;
  font-size: 16px;
  border-radius: 2em;
  line-height: 1.8em;
  padding: 0 .8em 0 .4em;
  width: $badge-work-type-width;
  //color: #fff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.work-type {
  position: relative;
  border-radius: 0;
  padding: 0;
  display: block;
  width: 100%;
  &:before {
    margin-right: 10px
  }
  background-color: transparent;
  &.cfa {
    color: $cfa_color;
  }
  &.bns {
    color: $bns_color;
  }
  &.mon {
    color: $monolit_color;
  }
  &.brk {
    color: $brick_color;
  }
}

.value.work-type {
  position: relative;
  width: auto;
}

//-----new work-type icons --------------------------
$worktype_btn_size: 20px; //габарити батона (20)
$worktype_btn_in_x: 2; //кількість іконок у спрайті ico-work-type.png (40px*40px)
$worktype_btn_in_y: 4; //кількість іконок у спрайті ico-work-type.png (40px*40px)
%worktype_custom_icones:before {
  background: url($rootpath + "/images/ico-work-type.png?version=202001") no-repeat;
  content: '';
  background-size: $worktype_btn_size * $worktype_btn_in_x;
  width: $worktype_btn_size;
  height: $worktype_btn_size;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
  //margin-bottom: 16px - $btn_size;
}

.work-type {
  &.cfa { // буроінєкційні палі
    @extend %worktype_custom_icones;
    //background-color: $cfa_color;
    &:before {
      background-position-x: 0 * $worktype_btn_size;
      background-position-y: 0 * $worktype_btn_size !important;
    }
  }
  &.bns { // буронабивні палі
    @extend %worktype_custom_icones;
    //background-color: $bns_color ;
    &:before {
      background-position-x: -1 * $worktype_btn_size;
      background-position-y: 0 * $worktype_btn_size !important;
    }
  }
  &.mon { // монолітні конструкції
    @extend %worktype_custom_icones;
    //background-color: $mon_color ;
    &:before {
      background-position-x: 0 * $worktype_btn_size;
      background-position-y: -1 * $worktype_btn_size !important;
    }
  }
  &.brk { // кладка
    @extend %worktype_custom_icones;
    &:before {
      background-position-x: -1 * $worktype_btn_size;
      background-position-y: -1 * $worktype_btn_size !important;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .doc-list .status .badge {
    max-width: 61px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.status {
  .badge {
    &.not-created { //Не создан
      background-color: #fff;
      border-color: #777;
      color: #777;
    }
    &.in-work { //В работе
      background-color: #777;
      border-color: #777;
      color: #fff;
    }
    &.on-statement { //На утверждении
      background-color: #fff;
      border-color: #F39C12;
      color: #F39C12;
    }
    &.approved { //Утвержден
      background-color: #F39C12;
      border-color: #F39C12;
      color: #fff;
    }
    &.signed { //Подписан
      background-color: #1ABB9C;
      border-color: #1ABB9C;
      color: #fff;
    }
  }
}

.fa-male-o,
.fa-female-o {
  font-size: 1.08em;
  &:before {
    color: #fff;
    font-family: 'FontAwesome';
    text-shadow: -1px -1px 0px $text-color, 1px -1px 0px $text-color, 1px 1px 0px $text-color, -1px 1px 0px $text-color, 0px 1px 0px $text-color, 0px -1px 0px $text-color;
  }
  &.green:before {
    text-shadow: -1px -1px 0px #1ABB9C, 1px -1px 0px #1ABB9C, 1px 1px 0px #1ABB9C, -1px 1px 0px #1ABB9C, 0px 1px 0px #1ABB9C, 0px -1px 0px #1ABB9C;;
  }
}

.fa-male-o:before {
  content: '\f183';
}

.fa-female-o:before {
  content: '\f182';
}

@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
  .page-title__metadatas li {
    width: 33%;
    padding: 10px 30px
  }
}

@media (max-width: $screen-md-max) {
  .page-title__metadatas li {
    width: 100%;
    padding: 10px 20px;
    label{
      width: 30%;
    }
  }
}


.page-title__metadatas {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #eee;
  li {
    display: flex;
    //justify-content: space-between;
    margin-bottom: -1px;
    //border-bottom: 1px solid #bbb;
    //width: 25%;
    padding: 10px 40px;
    float: left;
    label {
      font-weight: normal;
      margin-right: 15px
    }
    .value {
      font-weight: bold;
      color: $color1;
      &:after {
        font-family: FontAwesome;
        content: '\f040';
        margin-left: 15px;
        color: transparent;
      }
    }

    &:hover {
      .value:after {
        color: $color2;
      }
    }
  }
}

%overflow_ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.overflow_ellipsis {
  @extend %overflow_ellipsis
}

.expand_col {
  max-width: 20em;
}

.modal .x_panel {
  background-color: #f1f1f1;
  box-shadow:none;
  .ln_solid {
    border-top-color: $color_modal_xpanel_line;
  }
  .x_title {
    // border-bottom-color:$color_modal_xpanel_line;
  }
  .x_panel {
    background-color: #fafafa;
  }
}

.modal .modal-fullscreen{
  max-width: 100%;
  width: 99%;
}

.s_val {
  margin-right: 10px;
  border-radius: .5em .5em 0 .5em;
}

.s_unit {
  font-size: .8em;
  bottom: 0em;
  right: -2px;
  padding: 2px 3px;
  border-radius: .5em .5em .5em 0;
}

//----------new .btn like material des-----------------
$r_btn_size: 2.2em;
.content-table{
  table{
    .fa {line-height: 1.2em}
    .btn{
      width: $r_btn_size;
      height: $r_btn_size;
      line-height: 0.9 * $r_btn_size;
      border-radius: 50%;
      border-color: transparent;
      transition: unset;
      &.add-item{
        width:22px;height: 23px; line-height: 1.7em; color: $text-color;
      }
      &.ico{
        .fa:before{color: $text-color};
        %custom_fa_icones:before, %custom_fa_construction_icones:before {background-position-y: -3 * $btn_size; }
      }
      &.good{
        .fa:before {color: $color2}
        %custom_fa_icones:before, %custom_fa_construction_icones:before {background-position-y: -1 * $btn_size; }
      }
      &.notbad{
        .fa:before {color: $color_deadline_norm}
        %custom_fa_icones:before, %custom_fa_construction_icones:before {background-position-y: -5 * $btn_size; }
      }
      &.bad{
        .fa:before {color: $color_deadline_hot}
        %custom_fa_icones:before, %custom_fa_construction_icones:before {background-position-y: -4 * $btn_size; }
      }
    }
    tr:hover {
      .btn:not(.disabled){
        background-color: #fff;
        //border-color: $text-color;
      }
    }

  }
  &.has_btn_zone td.hovered,
  &:not(.has_btn_zone) tr:hover td{
    .btn:not(.disabled){
      %custom_fa_icones:before, %custom_fa_construction_icones:before {background-position-y: -2 * $btn_size; }
      .fa:before,&.add-item:before {color: #fff}
      background-color: $color2;
      border-color: darken($color2,12%);
      box-shadow: 0px 3px 3px rgba(0,0,0,.3);

      &:hover{
        //%custom_fa_icones:before, %custom_fa_construction_icones:before {background-position-y: -2 * $btn_size; }
        color: #fff !important;
        background-color: darken($color2,10%);
        .fa:before {color: #fff}
      }
      &.good{
        background-color: $color2;
        border-color: darken($color2,12%);
        &:hover{background-color: darken($color2,10%);}
      }
      &.notbad{
        background-color: $color_deadline_norm;
        border-color: darken($color_deadline_norm,12%);
        &:hover{background-color: darken($color_deadline_norm,10%);}
      }
      &.bad{
        background-color: $color_deadline_hot;
        border-color: darken($color_deadline_hot,12%);
        &:hover{background-color: darken($color_deadline_hot,10%);}
      }
    }
    .date_btn{
      border-color: #aaa;
    }
  }
}

//----------new widget print journal-----------------
.actions-btn button {margin: 0}
.actions-btn button .fa{margin-right: .4em}
.vertical-center{
  top: 50%;
  position: absolute;
  right: 1em;
}

.journal_ico{
  display: inline-block;
  font-size: 6em;
  width: 1em;
  height: 1em;
  background-size: contain;

  &.hidden_work_acts2{
    background-image: url('/images/ico_act_arm.png');
  }
  &.responsible_construction_act{
    background-image: url('/images/ico_act_arm.png');
  }
  &.hidden_work_acts{
    background-image: url('/images/ico_act_drill.png');
  }
  &.pile_field_act{
    background-image: url('/images/ico_act_pile_field.png');
  }
  &.income_control{
    background-image: url('/images/ico_act_pile_field.png');
  }
  &.zhbip{
    background-image: url('/images/ico_jouranl_CFA.png');
  }
  &.zhmon{
    background-image: url('/images/ico_jouranl_MON.png');
  }
  &.zhbns{
    background-image: url('/images/ico_jouranl_BNS.png');
  }
  &.zhbr{
    background-image: url('/images/ico_jouranl_CW.png');
  }
  &.pile_echo_protocols{
    background-image: url('/images/ico_pile_protocol.png');
  }
  &.cube_protocols{
    background-image: url('/images/ico_cube_protocol.png');
  }
  &.pile_graph{
    background-image: url('/images/ico_pile_graph.png');
  }
  &.additions{
    background-image: url('/images/ico_additions.png');
  }
  &.concrete_passports{
    background-image: url('/images/ico_concrete_passports.png');
  }
  &.carcass_passports{
    background-image: url('/images/ico_carcass_passports.png');
  }
  &.metal_certificates{
    background-image: url('/images/ico_metal_certificates.png');
  }
}

.btn.badge-filter{
  border: 1px solid $cfa_color;
  background-color: #fff;
  color: $cfa_color;
  margin-top: 10px;
  &:before{
    font-family: FontAwesome;
    content: '\f096';
    margin-right: 5px;
  }
  span{
    margin-left: 3px !important;
  }
  .badge{
    background: $cfa_color;
    color: #fff;
  }
  &:hover{
    background: lighten($cfa_color,30%);
    color: $cfa_color;
  }
  &.active{
    background: $cfa_color;
    color: #fff;
    &:before{
      content: '\f14a';
    }
    .badge{
      background: #fff;
      color: $cfa_color;
    }
  }
}

.badge_filter_wrapper{
  display: inline-block;
  vertical-align: bottom;
}

li[role="presentation"]{
  height: 36px;
}
ul.nav-tabs .badge-filter{
  margin-left: 3px !important;
  color: #fff;
}
ul.nav-tabs li.active .badge-filter{
  background-color: white !important;
  color: #5A738E !important;
}

.work_stage_list{
  list-style: none;
  padding: 0;
  margin: 0;
  > li{
    padding: 2px 0 ;
    *{
      line-height: 29px;
      vertical-align: text-top;
    }
    .btn:first-child{
      min-width: 160px;
      i{
        float: left;
        width: 20px;
        line-height: 32px !important;
        margin-left: 3px;
      }
    }
  }
}

.btn img{margin: -2px}

// іконки файлів з текстом
//.doc_with_text{
//  white-space: nowrap;
//  display: block;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  width: 170px;
//  direction: rtl;
//  text-align: left;
//  background: #eee;
//  position: relative;
//  border: 1px solid transparent;
//  border-radius: 4px;
//  line-height: 1.8em;
//  padding: 0px 7px 0px 23px;
//  text-align: center;
//  .fa{
//    position: absolute;
//    left: 2px;
//  }
//}
//a.doc_with_text{
//  background: #fff;
//  &:hover{
//    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3) ;
//  }
//}
//tr:hover a.doc_with_text{
//  border-color: #337AB7;
//}

  //--- кнопка налаштування пакету --
.package_settings{
  clear: both;
  float: right;
  margin-top: -30px;
  z-index: 2;
  position: relative;
  height: 36px;
  min-width: 200px;
  .btn{
    background: #f7f7f7;
    border: 1px solid transparent;
    padding: 0px 7px;
    border-radius: 3em;
    width: 2.5em;
    overflow: hidden;
    height: 2.5em;
    transition: width .7s;
    text-align: left;
    position: absolute;
    right: 0;
    .fa{
      font-size: 1.5em;
      line-height: 1em;
      width: 1em;
      height: 1em;
      &:before{color: $color2;}
    }
  }
  &:hover{
    .btn {width: 16.5em;}
  }
}
.right_col .row:hover{
  .package_settings .btn{
    background: $color2;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    .fa{
      &:before{color: #fff;}
    }
  }
}

.work-type[data-for="package-edit"]{
  cursor: pointer;
  .fa{
    border: 1px solid transparent;
    border-radius: 3em;
    width: 1.8em;
    height: 1.8em;
    line-height: 1.8em;
    margin-left: .2em;
    text-align: center;
  }
  &:hover{
    .fa{
      background: $color2;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
      &:before{color: #fff;}
    }
  }
}

//--- всі таби на овали --
//@media (max-width: 2550px) {
//  .has_tabs{
    .table-filter {
      margin-top: 25px;
    }
    ul.bar_tabs {
      border-bottom: none;
      margin: 21px 0 25px;
      background-color: transparent;
      li, a {
        border: none !important;
      }
      & > li {
        margin: 4px 8px;
        background-color: transparent;
        &.active {
          margin: 4px 8px;
          a, a:focus {
            background-color: $cfa_color;
            color: #fff;
          }
        }
        a {
          padding: 8px;
          border-radius: 3em;
        }
      }
    }
//  }
//}

@media (max-width: 1079px) {
  .btn-group.split-xxs .btn.btn-round{
    margin: 10px 0px;
    display: block;
    width: 100% !important;
    float: none;
    border-radius: 30px !important;
  }
}

@media (max-width: 1448px) {
  .vertical-center{
    top: unset;
    bottom: 25px;
    .btn-group.split-xxs .btn.btn-round{
      margin: 10px 0px;
      display: block;
      width: 100% !important;
      float: none;
      border-radius: 30px !important;
    }
  }
}

@media (max-width: 580px){
  .vertical-center{
    right: 0px;
  }
}
@media (max-width: 480px){
  .vertical-center{
    position: static;
  }
}

$gr_date-br: 5px;
.group_date{
  border-radius: $gr_date-br;
  margin: 4px 2px ;
  span{
    padding: 2px;
    margin: 0 !important;
    background: #eee;
    text-align: center;
    display: inline-block;
    border: 1px solid #ddd;
    &:first-child{
      border-radius: $gr_date-br 0 0 $gr_date-br;
    }
    &:last-child{
      border-radius: 0 $gr_date-br $gr_date-br  0;
    }
    &[class^="date"]{
      min-width: 5.5em;
    }
  }
  .days{
    display: inline-block;
    min-width: 3.5em;
    text-align: center;
  }
  .volume{
    display: inline-block;
    min-width: 4.5em;
    text-align: center;
  }
  .date_btn{
    background-color: #fff;
    cursor: pointer;
    &:hover{
      //border-color: #aaa;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    }
  }
  .a_name{
    min-width: 8em;
    text-align: center;
  }
  .a_weight{
    min-width: 4.5em;
    text-align: center;
  }
  .a_k{
    min-width: 3.5em;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    &.good{
      color: $good_color;
      border-color: #88cb88;
    }
    &.bad{
      color: $bad_color;
      border-color: #cb8888;
    }
  }
  .worse{background: #FFCFC8;}
  .better{background: #CBFFC8;}
}
.tooltip{
  .new_value{
    padding: 0 8px;
    &.worse{color:#ff7777}
    &.better{color:#77ff77}
  }
}

.label_first_only {
  & > div:not(:first-child){
    label{display: none}
  }
}

.status_drag_item{
  pointer-events: none;
}

.ready_for_drop{
  .dragTargetAtTable{
    &.hovered{
      background-color: #f3f3e6;
      //box-shadow:inset 0 0 0 1px #333;
      box-shadow: inset 0 2px 5px rgba(0, 0, 0, .1);
      .btn,.construction_label{pointer-events: none;}
    }
  }
}

//------- for Schema editor modal ---------------------
$panel_width : 300px;
$modal_body_padding : 15px;
$modal_wrap_height : 190px;
.schema_prop_panel{
  position: absolute;
  top: $modal_body_padding;
  right: $modal_body_padding;
  width: 0px;
  height: calc(100vh - #{$modal_wrap_height});
  background-color: #fff;
  border-left: 1px solid #777;
  z-index: 1100;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width .5s ;
  & > div {width: $panel_width - 1}
  &.active{width: $panel_width;}
}
.schema_general_panel{
  position: absolute;
  bottom: $modal_body_padding;
  right: $modal_body_padding;
  width: 0px;
  max-height: calc(100vh - #{$modal_wrap_height});
  //background-color: #fff;
  //border-left: 1px solid #777;
  z-index: 1200;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width .5s ;
  & > div {width: $panel_width - 4}
  &.active{width: $panel_width - 2;}
}

.def_val,
.def_val + .select2 .select2-selection {
  border-color: #06f;
  box-shadow: inset 0px 0px 5px rgba(0,102,255,0.5);
}

// modal collapse
.panel.compact{
  margin: 0px;
  .panel-heading,
  .panel-body {padding: .4em}
  .collapser {padding-left: 1.2em}
  .form-group {margin-bottom: 4px}
}
.collapser{
  cursor: pointer;
  padding-left: 2em;
  position: relative;
  &:before{
    display: inline-block;
    content: '\f0da';
    font-family: FontAwesome;
    position: absolute;
    left: .4em;
    transition: all .5s;
  }
  &.in{
    &:before{transform: rotate(90deg)}
  }
}


//custom sch_btn
$sch_btn_size : 24px;
$sch_btn_radius : 4px;

%text_in_sch_btn{
  float: left;
  width: auto;
  background-position: 5px 50%;
  line-height: 1.8em;
  color: #000;
  &:before{
    display: inline-block;
    margin: 0 10px 0 26px;
  }
}

.schema_tools_container{
  position: absolute;
  top:  $modal_body_padding;
  //left: 4.6 * $modal_body_padding;
  left: $modal_body_padding;
  z-index: 1200;
  & > div {
    margin: 10px 0 0 10px;
  }
  .mode_tools{
    //background-color: #fff;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    border-radius: $sch_btn_radius;
    .sch_btn{
      border-right: 1px solid #aaa;
      &:first-child{border-radius: $sch_btn_radius 0 0 $sch_btn_radius}
      &:last-child{border-radius: 0 $sch_btn_radius $sch_btn_radius 0; border-right: none }
    }
  }
  .edit_tools{
    margin-top: 50px;
    position: absolute;
    .sch_btn{
      border-radius: 50%;
      box-shadow: 0 1px 5px rgba(0,0,0,0.65);
      margin-bottom: 5px;
    }
  }
  .sch_btn{
    width: $sch_btn_size;
    height: $sch_btn_size;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: (0.56 * $sch_btn_size) (0.56 * $sch_btn_size);
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &:hover{background-color: #f4f4f4;}
    &.active{
      background-color: $cfa_color;
      box-shadow: inset 0 -1px 5px 2px rgba(0, 0, 0, 0.2);
    }
    &[mode="axis_mode"]{
      @extend %text_in_sch_btn;
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi43MDMyIiBoZWlnaHQ9IjE5Ljg3OSIgdmlld0JveD0iMCAwIDEyLjcwMzIgMTkuODc5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6M3B4O308L3N0eWxlPjwvZGVmcz48dGl0bGU+QXhpczwvdGl0bGU+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNi4zNTE2IiB5MT0iMTEuMjAzMiIgeDI9IjYuMzUxNiIgeTI9IjE5Ljg3OSIvPjxjaXJjbGUgY2xhc3M9ImNscy0xIiBjeD0iNi4zNTE2IiBjeT0iNi4zNTE2IiByPSI0Ljg1MTYiLz48L3N2Zz4=');
      &:before{content: 'Oci'};
    }
    &[mode="scale_mode"]{
      @extend %text_in_sch_btn;
      background-image: url('data:image/svg+xml;base64, PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy41NyA5LjYyIj48ZGVmcz48c3R5bGU+LmNscy0xLC5jbHMtMntmaWxsOm5vbmU7c3Ryb2tlOiMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fS5jbHMtMXtzdHJva2Utd2lkdGg6MC43NXB4O30uY2xzLTJ7c3Ryb2tlLXdpZHRoOjJweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnNjYWxlPC90aXRsZT48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIwLjM4IiB5MT0iOS42MiIgeDI9IjAuMzgiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIxNy4xOSIgeTE9IjkuNjIiIHgyPSIxNy4xOSIvPjxsaW5lIGNsYXNzPSJjbHMtMiIgeDE9IjQuNjgiIHkxPSI0LjkiIHgyPSIxMi44OSIgeTI9IjQuOSIvPjxwb2x5Z29uIHBvaW50cz0iNS41NiA3Ljg5IDAuMzggNC45IDUuNTYgMS45MSA1LjU2IDcuODkiLz48cG9seWdvbiBwb2ludHM9IjEyLjAxIDcuODkgMTcuMTkgNC45IDEyLjAxIDEuOTEgMTIuMDEgNy44OSIvPjwvc3ZnPg==');
      &:before{content: 'Масштаб'};
    }
    &[mode="walls_mode"]{
      @extend %text_in_sch_btn;
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC42MSAxMy41OSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjNweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPldhbGw8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMCAxLjUgOS40NiAxLjUgOS40NiAxMi4wOSAxOC42MSAxMi4wOSIvPjwvc3ZnPg==');
      &:before{content: 'Стіни'};
    }
    &[action="select"]{
      background-image: url('data:image/svg+xml;base64, PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC43OTc5IiBoZWlnaHQ9IjE4Ljc5NzkiIHZpZXdCb3g9IjAgMCAxOC43OTc5IDE4Ljc5NzkiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDAwO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDozcHg7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5zZWxlY3Q8L3RpdGxlPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjkuMjA4OCIgeTE9IjkuMjA4OCIgeDI9IjE3LjczNzIiIHkyPSIxNy43MzcyIi8+PHBvbHlnb24gcG9pbnRzPSI4LjI0OSAxNC4yODggMCAwIDE0LjI4OCA4LjI0OSA4LjI0OSAxNC4yODgiLz48L3N2Zz4= ');
    }
    &[action="add"]{
      background-image: url('data:image/svg+xml;base64, PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC42MSAxOC42MSI+PHRpdGxlPnBlbmNpbDwvdGl0bGU+PHBhdGggZD0iTTE4LjU2LDExLjQsOC4zNCwyMS42MkgzLjIzVjE2LjUxTDEzLjQ1LDYuMjlaTTguOCwxOC45Myw1LjkyLDE2LDQuOCwxNy4xNnYxLjMxSDYuMzdWMjBINy42OVptNS0xMC41NWEuMjkuMjksMCwwLDAtLjIxLjA5TDcsMTUuMTJhLjI5LjI5LDAsMCwwLS4wOS4yMS4yNi4yNiwwLDAsMCwuMjcuMjcuMjkuMjksMCwwLDAsLjIxLS4wOUwxNCw4Ljg2YS4yOS4yOSwwLDAsMCwuMDktLjIxQS4yNi4yNiwwLDAsMCwxMy44NCw4LjM4Wm03LjU0LjItMiwyTDE0LjIzLDUuNTFsMi0yQTEuNTQsMS41NCwwLDAsMSwxNy4zOCwzYTEuNTksMS41OSwwLDAsMSwxLjEyLjQ3bDIuODksMi44N2ExLjYzLDEuNjMsMCwwLDEsLjQ1LDEuMTJBMS41OCwxLjU4LDAsMCwxLDIxLjM4LDguNThaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4yMyAtMy4wMSkiLz48L3N2Zz4=');
    }
    &[action="del"]{
      background-image: url('data:image/svg+xml;base64, PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS41MyAxNy44NCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjJweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVyYXNlcjwvdGl0bGU+PHJlY3QgeD0iMTAuMzMiIHk9IjQuOSIgd2lkdGg9IjguODkiIGhlaWdodD0iOS4xMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQuMjIgMTAuMzgpIHJvdGF0ZSgtNDUpIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTkuODcsOC4yNiwxNiw0LjM2YTEuOCwxLjgsMCwwLDAtMi41NCwwbC0xMCwxMGExLjgsMS44LDAsMCwwLDAsMi41NGwyLjc0LDIuNzRIMTFsOC44Ny04Ljg3QTEuOCwxLjgsMCwwLDAsMTkuODcsOC4yNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xLjg2IC0yLjgzKSIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjQuMyIgeTE9IjE2Ljg0IiB4Mj0iMTcuNDIiIHkyPSIxNi44NCIvPjwvc3ZnPg==');
    }
    &[action="node_edit"]{
      background-image: url('data:image/svg+xml;base64, PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS45OCAxOS45OCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjNweDt9LmNscy0ye2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPm5vZGVfZWRpdDwvdGl0bGU+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMy43IiB5MT0iMy43IiB4Mj0iMTYuMjgiIHkyPSIxNi4yOCIvPjxjaXJjbGUgY2xhc3M9ImNscy0yIiBjeD0iNS44NCIgY3k9IjUuNzkiIHI9IjMuMzgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjM5IDMuODcpIHJvdGF0ZSgtNDUpIi8+PHBhdGggZD0iTTMuMTMsOC41YTMuODMsMy44MywwLDEsMCwwLTUuNDIsMy44NCwzLjg0LDAsMCwwLDAsNS40MlpNNy45MiwzLjcyYTIuOTMsMi45MywwLDEsMS00LjE1LDAsMi45NCwyLjk0LDAsMCwxLDQuMTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjAxIC0xLjk2KSIvPjxjaXJjbGUgY2xhc3M9ImNscy0yIiBjeD0iMTguMTYiIGN5PSIxOC4xMSIgcj0iMy4zOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuNSAxNi4xOCkgcm90YXRlKC00NSkiLz48cGF0aCBkPSJNMTUuNDUsMjAuODJhMy44MywzLjgzLDAsMSwxLDUuNDIsMCwzLjg0LDMuODQsMCwwLDEtNS40MiwwWk0yMC4yMywxNmEyLjkzLDIuOTMsMCwxLDAsMCw0LjE1LDIuOTQsMi45NCwwLDAsMCwwLTQuMTVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMi4wMSAtMS45NikiLz48L3N2Zz4=');
    }

  }
}

.passive{
  pointer-events: none !important;
}


// --- storeys -- яруси --
$storey_colors: (
      0 : #FFFF00 ,
      //1 : #CC9966 ,
      //2 : #B57C34 ,
      1 : #985734 ,
      //4 : #C35433 ,
      2 : #B4351C ,
      3 : #632714 ,
      4 : #4D0B0B ,
      5 : #660066 ,
      6 : #310091 ,
      7 : #000066,
);
$storey_color_active : #19B999;
$storey_color_selected : #0033FF;
$storey_color_finish : #105A37;

@each $i, $color in $storey_colors {
  path{
    &.storey_#{$i}{
      stroke: $color;
    }
  }
  .fa{
    &.storey_#{$i}{
      color: $color;
    }
  }
}
path{
  &.storey_finish{
    stroke:$storey_color_finish;
  }
  &.active{
    stroke:$storey_color_active;
  }
  &.selected{
    stroke:$storey_color_selected;
  }
  &.leaflet-interactive{
    stroke-linecap: butt;
  }
}
.fa.storey_finish{color:$storey_color_finish}
#storeys_prop_container .fa{margin: 0 7px;}
// Color Variables ======================================
$green: #75b800;
$gray-light: #eef1f3;

// Bar Variables ========================================
$bar-size: 5px;
$bar-radius: 60px;
$bar-bg: rgba(0,0,0,0.075);

.storey_status{
  .progress_bar {
    border-radius: $bar-radius;
    overflow: hidden;
    width: 100%;
    margin: 2px 0;
    &:hover{
      box-shadow: 0 1px 3px rgba(0,0,0, .4);
    }
    span {
      display: block;
      height: 7px;
    }
    @each $i, $color in $storey_colors {
        &.storey_#{$i}{
          .progress{background:$color}
        }
    }
    &.storey_finish{
      .progress{background:$storey_color_finish}
    }
  }
  .bar {
    background: $bar-bg;
  }
  .progress {
    background: $green;
    color: #fff;
    width: 0;
    margin: 0;
  }
}

#total_laying_material {
  .total_content {
    display: inline
  }
  span {
    margin-right: 20px;
    background-color: #eee;
    padding: 5px 15px;
    border-radius: 1em;
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .17);
  }
}




//------- for journals editor modal verum doc ---------
@import "_work_journal.scss";